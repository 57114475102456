import "./jquery.ihavecookies.min.js";
import Plyr from 'plyr';


$(function() {
    // ihavecookies
    $.each($("#ihavecookies"), function(){
        var $this = $(this);
        var $cookieTypes = $.parseJSON(JSON.stringify($this.data('cookietypes')));

            if (getUrlParameter("cookies") == 'reinit' || ($(".external_url").length > 0 && ($.fn.ihavecookies.cookie() === false || $.fn.ihavecookies.preference('analytics') === false))) {
                $this.ihavecookies({
                    title: $this.data('title'),
                    message: $this.data('message'),
                    delay: $this.data('delay'),
                    expires: $this.data('expires'),
                    link: $this.data('link'),
                    uncheckBoxes: false,
                    onAccept: function () {
                        checkCookies();
                    },
                    acceptBtnLabel: $this.data('acceptbtnlabel'),
                    advancedBtnLabel: $this.data('advancedbtnlabel'),
                    moreInfoLabel: $this.data('moreinfolabel'),
                    cookieTypesTitle: $this.data('cookietypestitle'),
                    fixedCookieTypeLabel: $this.data('fixedcookietypelabel'),
                    fixedCookieTypeDesc: $this.data('fixedcookietypedesc'),
                    cookieTypes: $cookieTypes
                },"reinit");
            } else {
                $this.ihavecookies({
                    title: $this.data('title'),
                    message: $this.data('message'),
                    delay: $this.data('delay'),
                    expires: $this.data('expires'),
                    link: $this.data('link'),
                    uncheckBoxes: false,
                    onAccept: function () {
                        checkCookies();
                    },
                    acceptBtnLabel: $this.data('acceptbtnlabel'),
                    advancedBtnLabel: $this.data('advancedbtnlabel'),
                    moreInfoLabel: $this.data('moreinfolabel'),
                    cookieTypesTitle: $this.data('cookietypestitle'),
                    fixedCookieTypeLabel: $this.data('fixedcookietypelabel'),
                    fixedCookieTypeDesc: $this.data('fixedcookietypedesc'),
                    cookieTypes: $cookieTypes
                });
            }


    });

    function checkCookies() {

        if ($.fn.ihavecookies.cookie() != false) {

            if($.fn.ihavecookies.preference('analytics') != false) {
                //Remember cookies for Matomo
                _mtm.push({'event': 'CookiesConsentGiven'});
            }else {
                //forgot cookies for Matomo
                _mtm.push({'event': 'CookiesConsentForget'});
            }

        }else {
            //forgot cookies for Matomo
            _mtm.push({'event': 'CookiesConsentForget'});
        }

        // Load iframe if cookie accepted
        if ($.fn.ihavecookies.cookie() !== false && $.fn.ihavecookies.preference('analytics') !== false) {
            var $externe_url = $(".external_url");
            if($externe_url.length > 0) {
                $externe_url.each(function() {
                    var $this = $(this);
                    var $externe_url_data_url = $this.data("url");

                    $this.find("iframe").attr('src', $externe_url_data_url);

                    if($this.hasClass("plyr__player")) {
                        $this.find(".player-wrap").css('display', 'block');
                    }else {
                        $this.find("iframe").css('display', 'block');
                    }

                    $this.find("p").remove();

                    if($this.hasClass("plyr__player")) {
                        setTimeout(() => {
                            plyr();
                        }, 500);
                    }


                });
            }
        }

    }

    function plyr() {
        const stream_players = {};
        let pathname = window.location.pathname;

        Array.from(document.querySelectorAll('.stream-player')).forEach(video => {
            stream_players[video.id] = new Plyr(video, {
                fullscreen: {
                    iosNative: true
                }
            });
            let config = JSON.parse($(video).attr("data-plyr-config"));
            let pathname = $(video).attr("data-pagename");
            let title = pathname + " - " + config.title;


            stream_players[video.id].on('ready', (event) => {
                $('.stream-player').removeClass('d-none');
            });

            stream_players[video.id].on('playing', (event) => {

                _mtm.push({
                    'event': 'Video',
                    'video_status': 'Playing',
                    'video_title': title
                });
            });

            stream_players[video.id].on('play', (event) => {

                _mtm.push({
                    'event': 'Video',
                    'video_status': 'Play',
                    'video_title': title
                });
            });

            stream_players[video.id].on('pause', (event) => {

                _mtm.push({
                    'event': 'Video',
                    'video_status': 'Pause',
                    'video_title': title
                });
            });

            stream_players[video.id].on('ended', (event) => {

                _mtm.push({
                    'event': 'Video',
                    'video_status': 'Ended',
                    'video_title': title
                });
            });
        });
    }

    /********************************************/
    /* GET URL PARAMETERS */
    /********************************************/
    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }

    checkCookies();
});