$(function() {

    // Get current url
    var $current_url = window.location.href;


    // Social Post Listing
    var $listing = $(".socialpostlisting .listing");

    $($listing).each(function () {
        var $this = $(this);
        var autorefresh = $this.parents(".socialpostlisting").attr("data-autorefresh");

        if (autorefresh > 0) {
            setInterval( function() { getNewSocialPost($current_url, $this) }, autorefresh );
        }


    });


    // Social Post Sliding
     var $sliding = $(".socialpostlisting .sliding");

     $($sliding).each(function () {
        var $this = $(this);
        var autorefresh = $this.parents(".socialpostlisting").attr("data-autorefresh");

        $this.slick({
            slidesToShow: 3.25,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="true" style=""><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg></button>',
            infinite:false,
            speed: 500,
            rows: 0,
            touchThreshold: 25,
            responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.25
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.25
                }
            }
            ,{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1.25
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1.25
                }
            }
            ]
        })
        .on('setPosition', function (event, slick) {
            // all slide same height
            slick.$slides.css('height', 'auto');
            slick.$slides.css('height', slick.$slideTrack.height() + 'px');
        });

        setTimeout(function(){
            $this.find('.item').removeClass('os-animation animate__animated').attr("data-os-animation","");
        }, 5000);

        if (autorefresh > 0) {
            setInterval( function() { getNewSocialPost($current_url, $this) }, autorefresh );
        }

     });


    // Live Update social post
    function getNewSocialPost(current_url, element) {

        if ($(element).hasClass("listing")) {
            var $element = ".listing";
        }else {
            var $element = ".sliding";
        }

        $.get( current_url, function( data ) {
            var $data = $(data);
            var $socialpost = $data.find($element).find(".item");

            // add news to content slider
            $($socialpost).reverse().each(function() {

                var $this = $(this);
                var $newId = $this.attr("id");
                var $existId = $(element).find('#' + $newId).length;

                // check if already exist
                if($existId === 0) {

                    if ($this.parents('.listing').length) {

                        var itemAnimation = $this.attr('data-os-animation');

                        $listing.prepend($this.addClass("animate__animated").addClass(itemAnimation));

                        var maxitem = $this.parents(".socialpostlisting").attr("data-maxitem");
                        var count = $listing.find(".item").length;

                        if(count > maxitem){
                            var exeed = count - maxitem;

                            $listing.find(".item:nth-last-of-type(-n+" + exeed + ")").remove()
                        }

                    }else {

                        // stay current slide when news are added
                        var currentSlide = $sliding.slick('slickCurrentSlide');
                        if(currentSlide > 0) {
                            $sliding.slick('slickGoTo', currentSlide+1, true);
                        }

                        // add news
                        var itemAnimation = $this.attr('data-os-animation');
                        $this.addClass("animate__animated").addClass(itemAnimation);
                        $sliding.slick('slickAdd',$this,null,true);

                        // remove animation
                        setTimeout(function(){
                            $sliding.find('.item').removeClass('os-animation animate__animated').attr("data-os-animation","");
                        }, 1000);

                    }
                }
            });
        });
    }


    // Reverse function
    $.fn.reverse = function() {
        return this.pushStack(this.get().reverse(), arguments);
    };

});